#wheel-arrow {
    width: 115px;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid black;
    margin: 0 auto;
    position: relative;
    top: -20px; /* Adjust to place correctly over the wheel */
    z-index: 1;
}


#wheel-container {
    width: 450px;
    height: 450px;
    margin: 30px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* #spinButton {
    display: block;
    margin: 20px auto;
} */
