
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
html,body,address,blockquote,div,dl,form,h1,h2,h3,h4,h5,h6,ol,p,pre,table,ul,dd,dt,li,tbody,td,tfoot,th,thead,tr,button,del,ins,map,object,a,abbr,acronym,b,bdo,big,br,cite,code,dfn,em,i,img,kbd,q,samp,small,span,strong,sub,sup,tt,var,legend,
fieldset,p, aside, header, footer, section, article {
	border: medium none;
	margin: 0;
	padding: 0;
    
}

:focus
{
	outline: none !important;
}

img,fieldset 
{
	border: 0 none;
}

a
{
	border: none;
	outline: none;
}

input[type="submit"], select
{
	-webkit-appearance:none;
}
img
{
	border: none;
	height: auto;
	max-width: 100%;
	outline: none;
	vertical-align: middle;
}
iframe
{
    vertical-align: middle;
}
a:hover
{
	text-decoration: none;
    color:red;
}

.clear
{
	clear: both;
}
ul::after
{
	clear: both;
	content: "";
	display: block;
}
ul
{
	list-style:none;
}

body
{
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 27px;
	color: #474646;
    -webkit-font-smoothing: antialiased;
    background: #0D0301;
}
h4, h5, h6, p, a {
    font-family: 'Montserrat', sans-serif;
}
div{
    font-family: 'Montserrat', sans-serif;
}
button.try-luck-btn {
    border-radius: 15px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 0px 23px 24px 0px rgba(199, 14, 119, 0.20);
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 40px;
    margin: 30px;
    border: none;
}
.modal-content {
    background: #000;
    border: 2px solid #ed2771;
    border-radius: 40px;
}
button.btn-close {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 15px;
    cursor: pointer !important;
    z-index: 1;
}
.modal-title.h4 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
}
.btn-close {
    filter: brightness(0) invert(1);
    opacity: 1;
}
.modal-header {
    border-bottom: 1px solid #fff3;
}   
.prediction-swiper-left {
    background: #450B21;
    border-radius: 20px;
    padding: 20px 30px;
    position: relative;
    min-height: 415px;
}
.slider-area-popup.row {
    padding-top: 25px;
}
.prediction-swiper-left h5 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 7px;
}
.piechart-diff {
    display: flex;
    justify-content: space-between;
    padding: 2px 0 10px;
}
p.true span {
    font-weight: 700 !important;
    font-size: 18px;
}
.countdown-area-top {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: -25px;
}
p.true {
    color: #ed2771;
    font-weight: 600;
    font-size: 14px;
}
p.false span {
    font-weight: 700 !important;
    font-size: 18px;
}

p.false {
    color: #FBB936;
    font-weight: 600;
    font-size: 14px;
}
.prediction-swiper-left h6 {
    text-align: center;
    color: #fff;
}
.prediction-swiper-left h6 span {
    color: #ed2771;
    font-weight: 500;
}
.prediction-swiper-left h6 b {
    font-size: 18px;
    font-weight: 600;
}
.prediction-swiper-left button {
    border-radius: 15px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 40px;
    width: -moz-fit-content;
    width: 100%;
    display: flex;
    margin: 25px auto 10px;
    justify-content: center;
    align-items: center;
}
.prediction-swiper-left .css-1mhcdve-MuiPieArc-root {
    stroke: transparent !important;
    stroke-width: 0;
    stroke-linejoin: round;
}
.modal-body {
    padding: 20px 30px;
}
.prediction-option {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    align-items: center;
}
p.optn-yes.active {
    border: 3px solid #fff;
}
.top-result p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url("../images/slide-arrow.png");
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: url("../images/slide-arrow.png");
    transform: rotate(180deg);
}
.top-result {
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    text-align: center;
    padding: 7px 10px;
}
.prediction-lost-content img {
    width: 100px !important;
    margin-bottom: 5px;
}
.prediction-right-area h5 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.css-187mznn-MuiSlider-root {
    color: #fbb936 !important;
    height: 6px !important;
}
.prediction-option p {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
}
.prediction-option h6 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px;
}
.prediction-right-area {
    padding: 30px;
}
.range-area h4 {
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin: 0 0 10px 0;
}
.outer-spacing-area {
    padding: 0 60px;
}
.outer-spacing-area button.accordion-button {
    background: #000;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.outer-spacing-area .accordion-item {
    background: #000;
    border: 1px solid #ffffff40;
    color: rgba(255, 255, 255, 0.40);
    font-size: 14px;
    line-height: 22px;
}
button.place-bet-btn.btn.btn-unset {
    border-radius: 15px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    width: 100%;
    border: none;
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 0;
    margin: 40px 0 10px;
}
p.optn-yes {
    background: #ed2771;
}
.outer-spacing-area  .accordion-button:focus {
    box-shadow: unset;
}
.outer-spacing-area .accordion-button:not(.collapsed) {
    box-shadow: unset;
}
.outer-spacing-area .accordion-button::after {
    filter: brightness(0) invert(1);
}
.outer-spacing-area .accordion-body {
    padding-top: 0;
}
p.optn-no {
    background: #fbb936;
}
.countdown-inner-content {
    text-align: center;
    margin: 0 3px;
}
.countdown-inner-content p {
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
}
.countdown-inner-content h3 {
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 5px 0 0 0;
}
.leader-board-top {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 10px;
    align-items: center;
}
.leader-board-top h5 {
    margin-bottom: 0;
}
.leader-board-top a {
    color: #d81178;
    font-weight: 600;
    font-size: 14px;
}
.leader-botton-content-mid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0;
}
.leader-botton-content-mid p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}
.leader-botton-content-mid h4 {
    font-size: 15px;
    color: #9C27B0;
    font-weight: 700;
}
.leader-board-outer-area {
    min-height: 260px;
    overflow: auto;
    padding: 0 15px;
}
.leader-board-outer-area::-webkit-scrollbar {
    width: 3px;
  }
   
  .leader-board-outer-area::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f379b2;
    border-radius: 10px;
  }
   
  .leader-board-outer-area::-webkit-scrollbar-thumb {
    background-color: #ec1379;
    border-radius: 10px;
  }
  .pie-chart-area {
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-number-area label {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 4px;
}
.number-options-outer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.number-options-outer p {
    background: #FBB936;
    width: 34px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    cursor: pointer;
    font-size: 14px;
    max-width: 34px;
    max-height: 30px;
}
.number-options-outer p.active {
    border: 1px solid #fff;
    background: #e91e63;
}
.bet-amount-area label {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 4px;
}
.spinner-popup .slider-area-popup.row {
    padding-top: 0;
}   
.select-number-area {
    margin-bottom: 20px;
}
.bet-amount-area input::placeholder {
    color: #ffffff69;
    font-size: 14px;
}
.bet-amount-area input {
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: none;
    padding: 14px;
    border-radius: 10px;
    color: #fff;
}
.bet-amount-area .form-control:focus {
    color: #fff;
    background-color: transparent;
    border-color: #d7d7d730;
    outline: 0;
    box-shadow: unset;
}
.spinning-option h6 {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    margin: 30px 0 15px 0;
}
.spinning-option label {
    color: #fff;
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    cursor: pointer;
}
.spinning-option .form-check-input {
    background-color: #fff0;
    border: 1px solid #dee2e68c;

}
.spinning-option .form-check-input:checked {
    background-color: #E91E63;
    border-color: #E91E63;
}
.spinning-option .form-check-input:focus {
    box-shadow: unset;
}
button.connect-wallet-btn-top {
    border-radius: 12px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
}
.header .navbar-nav {
    align-items: center;
}
.header .nav-link {
    color: #fff;
    font-weight: 600;
    margin: 0 13px;
}
.hero-banner {
    background: url("../images/hero-banner-second.png");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.banner-coins {
    position: absolute;
    bottom: 0;
    right: 100px;
}
.header nav.navbar {
    position: fixed;
    width: 100%;
    z-index: 1;
}
.russo-one {
    font-family: "Russo One", sans-serif;
font-weight: 400;
font-style: normal;
}
.hero-banner-content-right {
    text-align: right;
}
.hero-banner-content-right h1 {
    color: #fff;
    font-size: 90px;
}
.hero-banner-content-right h1 span {
    color: #E91E63;
}
.hero-banner-content-right p {
    color: #fff;
    font-size: 33px;
    font-weight: 500;
    line-height: 52px;
}
.marque-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.marque-content h2 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 800;
    margin: 0 40px;
}
.games-option {
    padding: 100px 0;
    margin: 60px 0 100px;
}
.features-area {
    border-radius: 15px;
    background: rgba(237, 39, 113, 0.35);
    padding: 50px 15px;
    height: 180px;
    max-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.prediction-area-left {
    position: relative;
}
.prediction-area-left h2 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
    font-weight: 400;
    position: relative;
    z-index: 1;
}
.prediction-area-left img {
    position: absolute;
    z-index: 0;
    top: -80px;
    left: 4px;
}
.prediction-area-left p {
    color: #fff;
    font-weight: 400;
    line-height: 31px;
}
.feature-ico {
    position: absolute;
    top: 4px;
    right: 3px;
}
.features-area h6 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    padding: 0 10px;
}
.spinner-area {
    padding: 100px 0;
}
.spinner-area .prediction-area-left {
    background-image: url("../images/spinner-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px;
    background-position: top;
    border-radius: 70px;
    border: 3px solid #ffffff21;
}
.single-thumb .range-slider__thumb[data-lower] {
    width: 0;
  }
  .single-thumb .range-slider__range {
    border-radius: 6px;
  }
.about-area {
    background-image: url("../images/about-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    margin: 80px 0 0 0;
}
.about-content-area h2 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
    font-weight: 400;
}
.about-content-area p {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin: 10px 0 60px 0;
}
.about-content-area {
    text-align: center;
}
.why-choose-area {
    background-image: url("../images/why-choose-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 130px 0 60px 0;
    background-position: bottom;
    position: relative;
}
.why-choose-content h2 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
    font-weight: 400;
}
.why-choose-content p {
    color: #fff;
    line-height: 32px;
}
.why-choose-content img {
    position: absolute;
    left: 50%;
    top: -75px;
    transform: translate(-50%, 0);
}
.why-choose-content button {
    border-radius: 15px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    color: #fff;
    padding: 10px 20px;
    font-weight: 500;
    margin: 40px 0 0 0;
}
.winners-area-content {
    border-radius: 50px;
    background: linear-gradient(268deg, #540071 2.72%, #ED2771 98.87%);
    padding: 20px 10px;
    text-align: center;
    position: relative;
}
.game-image img {
    width: 200px;
    min-width: 200px;
    height: auto;
}
.winners-info h6 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
}
.game-image {
    margin-bottom: 15px;
    height: 180px;
    max-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.winners-info p {
    color: #fff;
    font-weight: 600;
    padding: 5px 0 0 0;
}
.game-image {
    margin-bottom: 15px;
}
.winners-area h2 {
    background: linear-gradient(94deg, #FBB936 5.26%, #ED2771 80.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
}
.winners-area {
    padding: 60px 0;
}
.winners-area-content:after {
    content: "";
    position: absolute;
    background: none;
    width: 80%;
    height: 100%;
    left: 50%;
    border: 4px solid #FBB936;
    z-index: -1;
    border-radius: 40px;
    top: -15px;
    transform: translate(-50%, 0);
}
.winners-area .swiper-wrapper {
    padding: 30px 0 0 0;
}
.winners-area .swiper-button-next:after, .winners-area .swiper-rtl  .winners-area .swiper-button-prev:after {
    content: url("../images/winners-right-arrow.png");
}
.winners-area .swiper-button-prev:after, .winners-area .swiper-rtl .winners-area .swiper-button-next:after {
    content: url("../images/winners-right-arrow.png");
    transform: rotate(180deg);
}
.winners-area .swiper-button-next, .winners-area .swiper-rtl .winners-area  .swiper-button-prev {
    right: 8px;
}
.winners-area .swiper-button-prev, .winners-area .swiper-rtl .winners-area .swiper-button-next {
    left: 8px;
    right: auto;
}
.footer-top ul li {
    color: #fff;
    margin: 20px 20px 30px;
}

.footer-top ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-top {
    text-align: center;
}
.footer-bottom {
    background: linear-gradient(268deg, #ED2771 2.72%, #540071 98.87%);
    padding: 15px 0;
}
.footer-bottom-left p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}
.footer-bottom-right ul li i {
    color: #fff;
    margin: 0 8px;
    font-size: 20px;
}
.footer-bottom-right ul {
    display: flex;
    justify-content: right;
}
.feature-area {
    padding: 100px 0;
}
.feature-area .swiper-button-next:after, .feature-area .swiper-rtl  .feature-area .swiper-button-prev:after {
    content: url("../images/winners-right-arrow.png");
}
.feature-area .swiper-button-prev:after, .feature-area .swiper-rtl .feature-area .swiper-button-next:after {
    content: url("../images/winners-right-arrow.png");
    transform: rotate(180deg);
}
.feature-area .swiper-button-next, .feature-area .swiper-rtl .feature-area  .swiper-button-prev {
    right: 8px;
}
.feature-area .swiper-button-prev, .feature-area .swiper-rtl .feature-area .swiper-button-next {
    left: 8px;
    right: auto;
}
section.header.scrolled nav.navbar{
    background: #000;
    box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
    z-index: 99;
  }
  .my-slide-component {
    background: #5B1028;
    padding: 30px 30px 0;
    border-radius: 22px;
    width: 55% !important;
    margin: 0 auto;
    text-align: center;
    transition: 0.4s ease
}
.my-slide-component.active {
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.29);
    background: #0D0301;
}
/* .react-stacked-center-carousel-slide--1 {
    left: calc(50% - 490px) !important;
}
.react-stacked-center-carousel-slide-1 {
    left: calc(50% - 260px) !important;
} */
.react-stacked-center-carousel {
    overflow: unset !important;
    display: flex;
    align-items: center;
}
.games-option h5 {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.MuiFab-primary {
    color: #fff;
    background-color: #fbb936 !important;
    z-index: 9 !important;
}
.my-slide-component button.try-luck-btn.btn.btn-unset {
    margin: 0 0 -24px 0;
}
.game-slider-content h5 {
    padding: 25px 0;
}
.MuiFab-sizeSmall {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.marque-area {
    padding-top: 50px;
}
svg.MuiSvgIcon-root {
    width: 16px !important;
    height: 16px !important;
    color: #000;
    margin-right: -4px;
}
button#dropdown-basic {
    border-radius: 12px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
}
.connect-wallet-btn .dropdown-menu.show a {
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.connect-wallet-btn .dropdown-menu.show {
    background: #000;
    border: 2px solid #d11078;
    border-radius: 13px;
    margin-top: 5px;
}
.connect-wallet-btn .dropdown-item:focus, .connect-wallet-btn .dropdown-item:hover {
    background-color: #d11078;
}
.swal-modal {
    background: #000;
    border: 2px solid #E91E63;
    border-radius: 40px;
}
.swal-icon--success:after {
    background: #0000;
}
.swal-icon--success:before {
    background: #0000;
}
.swal-icon--success__hide-corners {
    background: #0000;
}
.swal-title {
    color: #fff;
}
.swal-text {
    color: #fff;
    text-align: center;
}
.swal-overlay {
    background-color: rgb(0 0 0 / 67%);
}
.swal-button {
    color: #fff;
    margin: 0;
    border-radius: 12px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    font-size: 15px;
    font-weight: 500;
    padding: 7px 25px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swal-footer {
    text-align: center;
    padding-top: 13px;
    margin-top: 0;
    padding: 22px 16px 25px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.no-data-area img {
    width: 65px;
    min-width: 65px;
}
.leader-board-content {
    text-align: center;
    padding: 0;
    /* animation: fadeIn 4s; */
}
.leader-board-content {
    animation: Zoomin .7s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes Zoomin {
    from {
        transform-origin: 50% 50%;
        transform: scale(.90);
        opacity: 0;
        box-shadow: rgba(0, 0, 0, .8) 0px 0px 50px inset;
    }
    to {
        opacity: 1;
        transform: scale(1);
        box-shadow: rgba(0, 0, 0, 0) 0 0 0 inset;
    }
}
.no-data-area p {
    color: #fff;
    font-weight: 500;
    margin: 12px 0 0;
}
.follow-steps-popup .modal-body h3 {
    color: #fff;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 28px;
}
.steps-content h6 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.steps-content p {
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    padding: 4px 0 0 0;
}
.steps-left-area h2 {
    background: #ed2771;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
}
.step-area {
    display: flex;
    margin: 40px 0 0 0;
}

.follow-steps-popup .modal-body {
    padding: 30px;
}
.steps-left-area {
    margin-right: 15px;
}
.loader {
    border: 6px solid #ffffffbf;
    border-radius: 50%;
    border-top: 6px solid #ed2771;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .outer-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000b8;
    z-index: 1090;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .main-loader {
    color: #ed2771;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
  }
  
  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 
      2em -2em 0 0em, 3em 0 0 -1em, 
      2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
      3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 
      2em -2em 0 0, 3em 0 0 0.2em, 
      2em 2em 0 0, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
       -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
       -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
       -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
      3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  .jumbo-detail ul {
    margin: 20px 0;
}
.jumbo-detail ul li {
    color: #fff;
    font-size: 14px;
    list-style: disc;
}
.jumbo-detail ul {
    margin: 6px 0 20px 0;
    padding-left: 40px;
}
.wallet-popup .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}
.wallet-popup .modal-body img {
    margin-bottom: 20px;
}
.wallet-popup .modal-body h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 4px;
}
.wallet-popup .modal-body {
    text-align: center;
    padding: 0 10px 20px;
}
.wallet-popup .modal-body p {
    font-size: 14px;
    color: #fff;
}
.wallet-popup .modal-footer {
    border-top: none;
    justify-content: center;
    padding: 5px 10px 30px;
}
.wallet-popup .modal-footer button.btn.btn-unset {
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    color: #fff;
    padding: 10px 17px;
    border-radius: 13px;
}
.prediction-area-left button {
    position: relative;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border-radius: 15px;
    margin-top: 35px;
}
.prediction-area-left .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .prediction-area-left .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .prediction-area-left .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .prediction-area-left .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .prediction-area-left .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .prediction-area-left .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s ease;
  }
  
  .prediction-area-left button:hover {
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    color: #ffffff;
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
}
  .prediction-area-left button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left  button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .prediction-area-left .fil0 {
    fill: #fffdef;
  }
  .header .navbar-toggler-icon {
    filter: brightness(0) invert(1);
}
.header .navbar-toggler {
    border: none;
    padding: 0 6px;
}
.header .navbar-toggler-icon {
    background-image: url("../images/header-menu.svg");
}
.header .navbar-nav {
    align-items: end;
}
#wheel-container {
    margin: 30px auto 20px;
}
.spin-wheel button {
    border-radius: 15px;
    display: flex;
    margin: 0 auto;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    color: #fff !important;
    font-size: 17px;
    font-weight: 500;
    padding: 10px 40px;
}
p.optn-no.active {
    border: 3px solid #fff;
}
.css-1aznpnh-MuiSlider-root {
    color: #E91E63 !important;
    margin-bottom: 0 !important;
}
.css-1eoe787-MuiSlider-markLabel {
    color: rgb(255 255 255 / 87%) !important;
}
.css-yafthl-MuiSlider-markLabel {
    color: rgb(255 255 255) !important;
}
/* .spinning-option.spin-wheel-option form {
    display: flex;
    justify-content: space-between;
} */
span.time-closed {
    position: absolute;
    border-radius: 10px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    color: #fff;
    padding: 3px 17px;
    right: 20px;
    top: -15px;
    font-size: 15px;
    font-weight: 500;
}
.btn-close:focus {
    box-shadow: unset !important;
}
.connect-wallet-btn .dropdown-menu.show a img {
    margin-right: 4px;
    min-width: 20px;
    width: 20px;
}
.swal-button:focus {
    box-shadow: unset;
}
/* .prediction-swiper-left.prediction-content {
    background: none;
    padding: 0;
} */
.prediction-swiper-left.prediction-content button {
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px 0 0 auto !important;
    padding: 9px 35px;
    box-shadow: none !important;
}
/* #wheel-arrow {
    width: 0;
    height: 0;
    border-left: 20px solid black;
    border-right: 20px solid black;
    border-bottom: 20px solid #fff;
    margin: 0 auto;
    position: relative;
    top: 0;
    z-index: 1;
    transform: rotate(180deg);
} */
.spin-wheel-outcome {
    background: #450B21;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 20px 100px 20px 20px;
    border-radius: 20px;
    color: #fff;
}
.spin-wheel-outcome h3 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
}
.spin-wheel-outcome ul {
    padding-left: 20px;
}
.spin-wheel-outcome ul li i b {
    margin-right: 12px;
}
.spin-wheel-outcome ul li {
    font-size: 14px;
    list-style: disc;
    padding: 5px 0;
}
.no-prediction-img img {
    width: 160px;
}
.no-prediction-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}
datalist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    writing-mode: vertical-lr;
    width: 100%;
    color: #db1078;
    margin-top: 0 !important;
  }
  
  option {
    padding: 0;
  }

input[type="range"] {
    width: 100%;
    margin: 0;
  }
.bet-amount-area {
    margin-bottom: 40px;
}
.rangebar-values {
    justify-content: space-between;
    display: flex;
    margin-top: -10px;
}
.form-range::-webkit-slider-thumb {
    background-color: #ed2771;
    margin-top: -6px;
}
.form-range::-webkit-slider-runnable-track {
    background-color: #ed2771c9;
    height: 4px;
}
.rangebar-values span {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
}
.range-area {
    margin-bottom: 15px;
}
.select-number-area .css-13cymwt-control {
    background-color: hsl(337.24deg 72.5% 15.69%);
    border-color: hsl(0deg 0% 80% / 25%);
}
.select-number-area .css-1u9des2-indicatorSeparator {
    background-color: hsl(336.82deg 27.16% 31.76%);
}
.select-number-area .css-1jqq78o-placeholder {
    color: hsl(0deg 0% 100% / 31%);
    font-size: 14px;
}
.select-number-area .css-1nmdiq5-menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: hsl(337.24deg 72.5% 15.69%);
    border-radius: 10px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 1px solid #673b4c;
}
.select-number-area .css-10wo9uf-option {
    color: #fff;
    padding: 5px 12px;
}
.select-number-area .css-d7l1ni-option {
    background-color: #e91e6359;
    color: #fff;
}
.select-number-area .css-1dimb5e-singleValue {
    color: #fff;
}
::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e91e6359;;
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color:  #ec1379 ;
    border-radius: 10px;
  }
  .no-data-area.main-no-data {
    text-align: center;
    padding: 50px 0;
}
.congrats-popup-area img {
    width: 180px;
}
.shimmer {
    background:linear-gradient(to right, #ffffff30 8%, #ffffff1c 18%, #ffffff29 33%) !important;
    background-size: 1000px 100% !important;
    animation: shimmer 2.2s linear infinite forwards !important;
}
 
span.time-closed.shimmer-btn-closed {
    background: none;
    box-shadow: none;
    display: flex;
    right: 5px;
}
.pie-chart-area.pie-shimmer {
    display: flex ;
    justify-content: center ;
    align-items: center ;
    left: 0;
}
.piechart-diff .shimmer-button--sm {
    border-radius: 3px;
    height: 22px;
    width: 130px;
    margin-bottom: 6px;
}
.pie-chart-area.pie-shimmer .shimmer.shimmer-avatar {
    margin-bottom: 4px;
}
.prediction-swiper-left h5 .shimmer.shimmer-button.shimmer-button--md {
    margin-bottom: 0;
}
h6.total-bet .shimmer.shimmer-button.shimmer-button--sm {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 22px;
    width: 180px;
}
.leader-board-top .shimmer.shimmer-button.shimmer-button--sm {
    height: 32px;
    width: 200px;
}
.leader-board-top a .shimmer.shimmer-button.shimmer-button--sm {
    width: 120px;
}
.leader-botton-content-mid .shimmer.shimmer-button.shimmer-button--sm {
    height: 15px;
}
.prediction-swiper-left .shimmer.shimmer-button.shimmer-button--lg {
    width: 100%;
    margin-bottom: 0;
    margin-top: 15px;
}
.prediction-right-area h5 .shimmer.shimmer-button.shimmer-button--md {
    width: 100%;
    height: 20px;
}
.range-area h4 .shimmer.shimmer-button.shimmer-button--md {
    display: flex;
    margin: 0 auto;
    height: 20px;
    width: 80%;
}
.range-area p .shimmer.shimmer-button.shimmer-button--md {
    display: flex;
    margin: 0 auto;
    height: 10px;
    width: 100%;
    margin: 30px 0;
}
.prediction-right-area h6 .shimmer.shimmer-button.shimmer-button--lg {
    width: 100%;
    margin-top: 60px;
}
.follow-steps-popup .modal-content {
    width: 80%;
}
.congratulation-popup .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}
.congratulation-popup .modal-body {
    text-align: center;
    color: #fff;
    padding: 0 30px;
}
.congratulation-popup .modal-body img {
    width: 250px;
}
.congratulation-popup .modal-body h5 {
    font-size: 24px;
    margin: 10px 0 0 0;
}
.congratulation-popup .modal-body p {
    font-size: 15px;
    line-height: 27px;
}
.modal-footer button {
    border-radius: 12px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    color: #fff !important;
}

.congratulation-popup .modal-footer {
    border-top: none;
    justify-content: center;
    padding: 20px 10px 30px;
}
img.lose-picture {
    max-width: 130px;
    width: 130px !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #fff;
}
span.time-closed.shimmer-btn-closed .shimmer.shimmer-button.shimmer-button--md {
    width: 50px;
    margin: 0 0 0 7px;
    height: 50px;
    border-radius: 10px;
}
.transaction-outer {
    padding: 110px 0 60px;
    min-height: 710px;
}
table.UserListTable.table {
    margin-bottom: 0;
}
.audit-request-box {
    background: #000000;
    border-radius: 30px;
    overflow: hidden;
    border: 2px solid #ec2671;
    padding: 12px;
}
.audit-request-box .table {
    --bs-table-bg: #0e030100;
    overflow: auto;
    cursor: pointer;
}
ul.pagination {
    justify-content: end;
    margin: 20px 25px 15px 10px;
}

ul.pagination li a {
    color: #ffffff !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

ul.pagination li {
    border: 2px solid #ffffff;
    width: 45px;
    height: 30px;
    min-width: 40px;
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
    cursor: pointer;
}
ul.pagination li.active {
    background: #ec1379;
}
.audit-request-box table.UserListTable.table tr th {
    color: #fff;
    font-weight: 600;
    padding: 13px;
    white-space: nowrap;
}
.audit-request-box table.UserListTable.table tr td a {
    color: #ec1379;
    font-weight: 500;
}
.audit-request-box table.UserListTable.table tr td {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    padding: 17px;
    white-space: nowrap;
}
.no-data-area.main-no-data.table-no-data {
    min-height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.no-data-area.main-no-data.table-no-data img {
    width: 80px;
}

.spin-allocation-inner button.btn.btn-primary {
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
}
.spin-allocation-inner {
    display: flex;
    align-items: center;
    padding: 2px 0;
}
.spin-allocation-inner button.btn.btn-primary i {
    font-size: 15px;
    margin-left: 7px;
}
.heading-top-area h2 {
    font-weight: 600;
    color: #fff;
    font-size: 22px;
}
.transaction-tab ul.nav.nav-tabs {
    border-bottom: 0;
    margin: 25px 0 15px 0;
}
.transaction-tab .nav-link {
    color: #ec2671;
    border: 2px solid #ec2671 !important;
    border-radius: 10px !important;
    font-weight: 600;
    padding: 4px 15px;
    font-size: 15px;
    margin: 0 8px 0 0;
}
.transaction-tab .nav-tabs .transaction-tab .nav-item.show .nav-link, .transaction-tab .nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    color: #ffff;
    border: none !important;
    padding: 6px 15px;
}
.fade.isBlur.spinner-popup.spin-wheel-popup.modal.show {
    position: fixed;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99;
}
span.prediction-board-ans {
    color: #ed2771 !important;
}
.css-9cct2k {
    color: #ec1379 !important;
    margin-bottom: 0 !important;
}
.css-sxo8tj {
    color: rgb(255 255 255 / 87%) !important;
}
.css-60f6gz {
    color: rgb(255 255 255) !important;
}
.prediction-detail-area {
padding: 100px 0;
}
.prediction-market-area h2 {
    text-align: left;
    font-size: 35px;
    color: #fff;
    font-weight: 600;
}
.explore-top-filter {
    display: flex;
    justify-content: end;
}
.filter-area-top {
    align-items: center;
    justify-content: end;
    margin: 0 0 0 7px;
}
.filter-area-top label {
    margin-bottom: 0;
    color: #fff;
    font-weight: 500;
    margin-right: 7px;
    font-size: 15px;
} 
.filter-area-top .css-13cymwt-control {
    border-radius: 10px;
    border: 1px solid rgb(255 255 255 / 23%);
    background: #0d0301;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
    width: 120px;
    cursor: pointer;
}
.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
}
.explore-top-filter .css-b62m3t-container {
    width: 120px;
}
.css-1u9des2-indicatorSeparator {
    background-color: hsl(0deg 0% 80% / 27%);
}
.prediction-box {
    border-radius: 15px;
    background: #ffffff26;
    box-shadow: -9.131px 17.433px 39.846px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.prediction-market-inner {
    display: flex;
}
.prediction-market-left {
    margin-right: 15px;
}
.prediction-market-left img {
    width: 65px;
    min-width: 65px;
    height: 65px;
    min-height: 65px;
    object-fit: cover;
    border-radius: 4px;
}
.prediction-market-right {
    margin-top: -25px;
    width: 80%;
}
.prediction-market-right h5 {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 3px;
}
.prediction-market-right p {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.bg-vector {
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    top: -2px;
    display: flex;
    margin: 0 0 0 auto;
}
.bg-vector p {
    position: absolute;
    top: 33%;
    left: 50%;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}
.prediction-box ul {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    border-top: 1px solid #ffffff30;
    border-bottom: 1px solid #ffffff30;
    padding: 8px 0;
}
.prediction-box ul li {
    width: 50%;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}
.prediction-box ul li i {
    margin-right: 6px;
}
.prediction-boxt ul li p {
    font-size: 11px;
    font-weight: 700;
    display: flex;
}
.prediction-box ul li p span {
    color: #ffffff;
    font-weight: 500;
    padding: 0;
}
.prediction-box ul li p {
    font-size: 13px;
    color: #ed2771;
}
.prdiction-box-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.prdiction-box-bottom p {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.prdiction-box-bottom p span {
    color: #ed2771;
}
.prdiction-box-bottom button {
    border-radius: 10px;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
    padding: 5px 15px;
    font-size: 15px;
    font-weight: 500;
    color: #ffff;
}
button.viw-more-btn {
    color: #fff !important;
    border-radius: 12px;
    font-size: 16px !important;
    font-weight: 500;
    padding: 9px 23px;
    display: flex;
    margin: 20px auto 0;
    width: -moz-fit-content;
    width: fit-content;
    background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
    box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
    border: none;
}
/* .react-stacked-center-carousel-slide--1 {
    left: calc(50% - 525px) !important;
}
.react-stacked-center-carousel-slide-1 {
    left: calc(50% - 230px) !important;
} */
button.disable-btn {
    cursor: not-allowed !important;
    background: linear-gradient(0deg, #d84b8d 0%, #663776 124.55%);
}
.disable-area {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}
.disable-area button, .disable-area .css-b62m3t-container, .disable-area .MuiBox-root, .disable-area .spin-allocation-inner {
pointer-events: none;
}
.chooser-spin-no:hover {
    transform: scale(var(--scaling-hover, 1.3));
    background: #e91e63;
}
.chooser-spin-no {
    width: 30px;
    height: 30px;
    background: #fbb936;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 500;
    cursor: pointer;
    margin: 5px;
    font-size: 15px;
    transition: ease-in-out 0.4s;
}
.spin-no-area .swiper-button-next, .spin-no-area .swiper-rtl .spin-no-area .swiper-button-prev {
    right: 7px;
}
.spin-no-area .swiper {
    padding: 10px 40px;
}
.spin-no-area .swiper-button-prev, .spin-no-area .swiper-rtl .spin-no-area .swiper-button-next {
    left: 7px;
}
.chooser-spin-no.active {
    background: #e91e63;
    border: 1px solid #fff;
}
.spin-no-area {
    margin-bottom: 20px;
}
.spin-no-area label {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 4px;
}
.modal-backdrop {
    --bs-backdrop-opacity: 0.8;
}
canvas#wheel {
    width: 450px;
     height: 450px;
}
.follow-steps-popup .modal-dialog.modal-dialog-centered {
    justify-content: center;
}
button.swal-button.swal-button--cancel {
    background: #9c27b000 !important;
    border: 2px solid #d41078;
    color: #d41078;
    font-weight: 700;
}
.prediction-swiper-left button img {
    margin-right: 5px;
}
:root {
    --swiper-navigation-size: 30px;
}
.no-data-area {
    padding: 30px 0;
    min-height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.table-responsive-xxl {
    cursor: pointer;
    min-height: 400px;
}
  /* media-query  */


  @media (max-width: 1400px) {

    .winners-info h6 {
        font-size: 18px;
    }
    .winners-info p {
        padding: 0;
        font-size: 14px;
    }
  }
  @media (max-width: 1200px) { 
    .spin-wheel-popup .modal-lg, .modal-xl {
        --bs-modal-width: 100% ;
        margin: 0 10px;
    }
    .hero-banner {
        height: 90vh;
    }
    .hero-banner-content-right h1 {
        font-size: 70px;
    }
    .spinner-area .prediction-area-left {
        padding: 50px;
    }
    .features-area h6 {
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px;
    }
    .feature-area {
        margin-bottom: 80px;
    }
    .features-area {
        height: 160px;
        max-height: 160px;
    }
    .marque-content h2 {
        font-size: 45px;
        margin: 0 35px;
    }
   }
   @media (max-width: 992px) {
    .prediction-right-area.predict-popup-game {
        margin: 0;
        padding: 10px 30px;
    }
    #wheel-container {
        width: 330px;
        height: 330px;
        margin: 30px auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    canvas#wheel {
        width: 330px;
        height: 330px;
    }
    p.true span, p.false span {
    font-size: 14px;
}
    .header .navbar-nav {
        align-items: start;
        padding-bottom: 10px;
    }
    .header .nav-link {
        margin: 0;
    }
    .outer-spacing-area {
        padding: 0 10px;
    }
    .spin-wheel button {
        font-size: 15px;
        padding: 9px 35px;
    }
    .countdown-inner-content p {
        background: linear-gradient(0deg, #EC1379 0%, #540071 124.55%);
        box-shadow: 10px 13px 24px 0px rgba(0, 0, 0, 0.47);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 500;
        width: 35px;
        min-width: 35px;
        height: 35px;
        min-height: 35px;
    }
    span.time-closed.shimmer-btn-closed .shimmer.shimmer-button.shimmer-button--md {
        width: 35px;
        height: 35px;
        border-radius: 10px;
    }
    .countdown-inner-content h3 {
        font-size: 7px;
    }
    .prediction-right-area {
        padding: 10px;
        margin: 0 25px 0 0;
    }
    p.false, p.true {
        font-size: 12px;
    }
    .prediction-swiper-left h6 {
        font-size: 14px;
    }
    .leader-board-top h5 {
        font-size: 15px;
    }
    .prediction-swiper-left h6 b {
        font-size: 15px;
    }
    .spin-wheel-popup .modal-lg, .modal-xl {
        --bs-modal-width: 100%;
        margin: 10px;
    }
    .spin-allocation-inner {
        margin: 0 19px 0 0;
    }
    .modal-title.h4 {
        font-size: 20px;
    }
    .prediction-swiper-left.prediction-content button {
        margin: 10px 0 0 auto !important;
        padding: 8px 28px;
        font-size: 15px;
    }
    .header nav.navbar {
        background: linear-gradient(67deg, #00000054 0%, #000000 124.55%);
    }
    .number-options-outer p {
        width: 26px;
        height: 26px;
        max-width: 26px;
        max-height: 26px;
    }
    .bet-amount-area {
        margin-bottom: 30px;
    }
    .prediction-swiper-left {
        padding: 20px;
        margin-bottom: 20px;
    }
    .spinning-option.spin-wheel-option form {
        display: flex;
        flex-wrap: wrap;
    }
    .hero-banner {
        height: 70vh;
    }
    /* .banner-coins {
        display: none;
    } */
    .banner-coins img {
        width: 230px;
    }
    
    .banner-coins {
        position: absolute;
        bottom: -50px;
        right: 100px;
    }
    .prediction-area-left img {
        position: absolute;
        z-index: 0;
        top: -40px;
        left: 4px;
    }
    .winners-area h2 {
        font-size: 50px;
    }
    .prediction-area-left h2 {
        font-size: 50px;
    }
    .spinner-area .prediction-area-left {
        padding: 30px;
    }
    .prediction-area-left p {
        color: #fff;
        font-weight: 400;
        line-height: 24px;
        font-size: 14px;
    }
   }
   @media (max-width: 768px) {
    .transaction-tab .nav-link {
        margin: 0 8px 10px 0;
    }
    .spin-wheel-popup .modal-body {
        padding: 15px;
    }
    .prediction-right-area h5 {
        font-size: 17px;
        line-height: 26px;
        padding: 0 30px;
    }
    .prediction-game-popup .prediction-swiper-left button {
        width: fit-content;
    }
    .header nav.navbar {
        background: linear-gradient(67deg, #000000 0%, #000000 124.55%);
    }
    .hero-banner {
        background: url("../images/hero-banner-mob.png");
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .hero-banner-content-right p {
        font-size: 23px;
        line-height: 36px;
    }
    .hero-banner-content-right h1 {
        font-size: 45px;
    }

    .my-slide-component {
        width: 70% !important;
    }
    .prediction-area-left {
        margin-bottom: 50px;
    }
    .about-content-area h2 {
        font-size: 50px;
    }
    .about-content-area p {
        font-size: 16px;
        line-height: 30px;
    }
    .why-choose-content h2 {
        font-size: 50px;
    }
    .why-choose-content p {
        line-height: 30px;
    }
    .footer-top ul li {
        margin: 20px 10px 30px;
        font-size: 14px;
    }
    .footer-bottom-right ul {
        justify-content: center;
        margin-top: 6px;
    }
    }
    @media (max-width: 767px) {
        .prediction-area.spinner-area .row {
            flex-direction: column-reverse;
        }
    }

    @media (max-width: 576px) { 
        .banner-coins {
            position: absolute;
            bottom: -50px;
            right: 10px;
        }
        canvas#wheel {
            width: 350px;
            height: 350px;
        }
        #wheel-container {
            width: 350px;
            height: 350px;
        }
        .spin-allocation-inner {
            padding: 0;
            margin: 0;
        }
        .spin-wheel-popup .modal-body {
            padding: 20px 10px;
        }
        .prediction-game-popup .modal-body {
            padding: 10px;
        }
        .bet-amount-area label, .spin-no-area label {
            font-size: 16px;
            margin-bottom: 0;
        }
        .spinning-option h6 {
            font-size: 16px;
            line-height: 23px;
        }
        .spin-wheel-popup .prediction-swiper-left {
            padding: 15px;
        }
        .footer-top img {
            width: 190px;
        }
        .header nav.navbar img {
            width: 190px;
        }
        .prediction-area-left h2 {
            font-size: 30px;
        }
        .about-content-area h2 {
            font-size: 30px;
        }
        .winners-area h2 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        .footer-top ul {
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        .why-choose-content h2 {
            font-size: 30px;
        }
        .footer-top ul li {
            margin: 4px 10px 0;
            font-size: 14px;
        }
        .footer-bottom-left p {
            text-align: center;
        }
    }
    @media (max-width: 480px) {
        .prediction-right-area.predict-popup-game {
            padding: 10px 0;
        }
        .prediction-area-left p {
            text-align: justify;
        }
        .swal-button {
            font-size: 14px;
            height: 42px;
        }
        .swal-title {
            font-size: 22px;
        }
        button.btn-close {
            position: absolute;
            right: 15px;
            top: 13px;
            font-size: 12px;
        }
        .banner-coins {
            display: none;
        }
        .leader-board-outer-area {
            padding: 0 5px 0 0;
        }
        .heading-top-area h2 {
            font-size: 20px;
        }
        .follow-steps-popup .modal-content {
            width: 100%;
        }
        .steps-left-area h2 {
            background: #ed2771;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: 700;
        }
        .loader {
            border: 6px solid #ffffffbf;
            border-radius: 50%;
            border-top: 6px solid #ed2771;
            width: 30px;
            height: 30px;
            animation: spin 2s linear infinite;
        }
        .steps-content p {
            font-size: 14px;
        }
        .step-area {
            display: flex;
            margin: 35px 0 0 0;
        }
        .steps-content h6 {
            font-size: 15px;
        }
        .follow-steps-popup .modal-body {
            padding: 20px;
        }
        .follow-steps-popup .modal-body h3 {
            margin-bottom: 10px;
            font-size: 25px;
        }
        .leader-board-top h5 {
            font-size: 14px;
        }
        .leader-board-top a {
            font-size: 11px;
        }
        .transaction-tab .nav-link {
            padding: 3px 10px;
            font-size: 13px;
        }
        .leader-botton-content-mid p, .leader-botton-content-mid h4  {
            font-size: 13px;
        } 
  
        .prediction-option {
            margin: 10px 0;
        }
        .prediction-option p {
            width: 45px;
            height: 45px;
            font-size: 14px;
        }
        p.true span, p.false span {
            font-size: 14px;
        }
        .piechart-diff {
            display: flex;
            justify-content: center;
            padding: 2px 0 5px;
            flex-wrap: wrap;
        }
        .modal-header {
            padding: 7px 10px;
        }
        .spin-no-area {
            margin-bottom: 0;
        }
        .bet-amount-area label, .spin-no-area label {
            font-size: 15px;
        }
        .spinning-option h6 {
            font-size: 15px;
            margin: 30px 0 5px 0;
        }
        .modal-title.h4 {
            font-size: 18px;
        }
        .modal-content {
            border-radius: 20px;
        }
        canvas#wheel {
            width: 250px;
            height: 250px;
        }
        #wheel-container {
            width: 250px;
            height: 250px;
        }
        .spin-wheel-popup .prediction-swiper-left {
            padding: 10px;
        }
        .spin-no-area .swiper {
            padding: 10px 35px;
        }
        .prediction-right-area {
            padding: 15px 0 0;
        }
        .prediction-right-area h5 {
            font-size: 15px;
            line-height: 22px;
        }
        button.place-bet-btn.btn.btn-unset {
            font-size: 14px;
            padding: 12px 30px;
            margin: 30px auto 0;
            display: flex;
            width: fit-content;
        }
        .prediction-swiper-left button {
            font-size: 14px;
            padding: 10px 18px;
        }
        .prediction-swiper-left {
            padding: 25px 12px 12px;
        }
        .spinning-option label {
            font-size: 12px;
        }
        .my-slide-component {
            padding: 15px 15px 0;
        }
        .spin-allocation-inner button.btn.btn-primary i {
            font-size: 13px;
            margin-left: 5px;
        }
        .games-option h5 {
            font-size: 18px;
        }
        button.try-luck-btn {
            font-size: 15px;
            padding: 10px 25px;
        }
        section.marque-area {
            padding-top: 0;
        }
  
        .hero-banner-content-right h1 {
            font-size: 30px;
            margin-bottom: 6px;
        }
        .hero-banner-content-right p {
            font-size: 15px;
            line-height: 24px;
        }
        .hero-banner {
            background: url("../images/hero-banner-mob2.png");
            background-size: cover;
            background-position: bottom;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: baseline;
            position: relative;
            padding-top: 75px;
        }
        .hero-banner-content-right {
            text-align: center;
        }
        .feature-area {
            padding: 40px 0;
        }
        .my-slide-component {
            width: 100% !important;
        }
        .footer-bottom-left p {
            line-height: 22px;
            font-size: 12px;
        }
        .about-area {
            margin: 0;
        }
        .why-choose-content button {
            padding: 8px 20px;
            font-size: 14px;
        }
        .about-content-area p {
            font-size: 14px;
            line-height: 26px;
        }
        .why-choose-content p {
            line-height: 26px;
            font-size: 14px;
        }
        .prediction-area-left button {
            padding: 7px 30px;
            font-size: 15px;
        }
        .why-choose-content h2, .about-content-area h2 {
            font-size: 25px;
        }
        .prediction-area-left h2 {
            font-size: 25px;
        }
        .why-choose-content img {
            top: -45px;
            width: 110px;
        }
        .why-choose-area {
            padding: 90px 0 60px 0;
        }
  
    }